<template>
  <form
    class="account"
    :class="{ 'py-2': error !== '' }"
    @submit.prevent="onSubmitForm"
  >
    <div
      class="grid rounded p-8 sm:p-0 flex-col sm:flex-row sm:items-center sm:justify-end fix-height-nev"
    >
      <div class="grid sm:flex">
        <h1 class="sm:hidden text-2xl font-bold text-white text-center mb-1">
          {{ $t('signIn') }}
        </h1>
        <div class="grid">
          <div class="grid sm:flex">
            <div class="mb-4 sm:mb-0">
              <label
                class="block sm:hidden text-white text-sm font-bold mb-2"
                for="username"
              >
                {{ $t('username') }}
              </label>
              <input
                v-model="username"
                :class="{
                  'border-red-500 bg-red-50':
                    (isSubmit && !username) || error !== '',
                }"
                autocomplete="off"
                class="shadow border rounded text-grey-darker w-full py-2 px-3 sm:py-1 sm:px-1 sm:text-xs"
                type="text"
                :placeholder="$t('username')"
                @input="onUsernameInput"
              />
            </div>
            <div class="mb-6 sm:mb-0">
              <label
                class="block sm:hidden text-white text-sm font-bold mb-2"
                for="password"
              >
                {{ $t('password') }}
              </label>
              <input
                v-model="password"
                :class="{
                  'border-red-500 bg-red-50':
                    (isSubmit && !username) || error !== '',
                }"
                autocomplete="off"
                class="shadow border rounded w-full py-2 px-3 sm:py-1 sm:px-1 text-grey-darker sm:mb-0 sm:ml-2 sm:text-xs"
                type="password"
                :placeholder="$t('password')"
                @input="onPasswordInput"
              />
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <button
            class="sign-in text-white font-bold py-2 px-4 sm:py-1 sm:px-2 rounded w-full text-sm sm:text-xs sm:ml-2 sm:w-20"
            type="submit"
            :disabled="loadingStore.is('login')"
          >
            <font-awesome-icon
              v-if="loadingStore.is('login')"
              icon="fa-solid fa-spinner"
              class="animate-spin"
            />
            <span v-else>
              {{ $t('signIn') }}
            </span>
          </button>
        </div>
      </div>
      <div v-if="error !== ''" class="text-red-500 text-xs">
        {{ $t(error) }}
      </div>
    </div>
  </form>
</template>

<script lang="ts">
export default {
  name: 'LoginForm',
};
</script>

<script lang="ts" setup>
import { onUnmounted, ref } from 'vue';
import { useLoadingStore } from '../../store/wait';

import type { LoginInput } from '../../interfaces/api/LoginInput.interface';

defineProps<{
  error: string;
}>();

const emit = defineEmits(['submit:login']);

const loadingStore = useLoadingStore();

const username = ref('');
const password = ref('');
const isSubmit = ref(false);

const onUsernameInput = () => {
  username.value = username.value.trim();
  username.value = username.value.replaceAll(' ', '');
};

const onPasswordInput = () => {
  password.value = password.value.trim();
  password.value = password.value.replaceAll(' ', '');
};

const onSubmitForm = () => {
  isSubmit.value = true;
  if (username.value && password.value) {
    const payload: LoginInput = {
      username: username.value,
      password: password.value,
    };
    emit('submit:login', { ...payload });
  }
};

onUnmounted(() => {
  isSubmit.value = false;
  username.value = '';
  password.value = '';
});
</script>

<style>
.fix-height-nev {
  height: 42px;
}
.account input[type='number'],
.account input[type='password'],
.account input[type='text'] {
  width: 100px;
}
input {
  box-shadow: inset 1px 0 1px 1px #666;
}
.sign-in.submit-btn__bg-disable {
  background-color: #f8ca9a;
  cursor: no-drop;
}
.sign-in {
  background-color: #f1983b !important;
  border-color: #fdc271 !important;
  height: 28px;
  width: 70px;
}
.sign-in:hover {
  background-color: #f18919 !important;
  border-color: #f18919 !important;
}
@media (max-width: 639px) {
  .fix-height-nev {
    height: auto;
  }
  .sign-in {
    height: auto;
    width: 100%;
  }
  .account input[type='number'],
  .account input[type='password'],
  .account input[type='text'] {
    width: 100%;
  }
}
</style>
