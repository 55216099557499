<template>
  <section class="sm:hidden">
    <login-container />
  </section>
  <section>
    <home-banner />
  </section>
  <section>
    <main-content />
  </section>

  <teleport to="body">
    <notify-modal
      :message="modalMessage"
      :is-open="showModal"
      @close-notify-modal="onCloseNotifyModal"
    />
  </teleport>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import HomeBanner from '@/components/Login/HomeBanner.vue';
import MainContent from '@/components/Login/MainContent.vue';
import LoginContainer from '@/containers/LoginContainer.vue';
import NotifyModal from '@/components/NotifyModal.vue';
import { useModalStore } from '../store/modal';

export default defineComponent({
  name: 'LoginPage',
  components: {
    LoginContainer,
    HomeBanner,
    MainContent,
    NotifyModal,
  },

  setup() {
    const modalStore = useModalStore();

    const modalMessage = computed(
      () => modalStore.message ?? 'Something went wrong'
    );
    const showModal = computed(() => modalStore.isShow);

    const onCloseNotifyModal = () => {
      modalStore.isShow = false;
    };

    return {
      onCloseNotifyModal,
      modalMessage,
      showModal,
    };
  },
});
</script>

<style>
.margin-left-diff-6 {
  margin-left: -6px;
}
@media (max-width: 639px) {
  .margin-left-diff-6 {
    margin: 0;
  }
}
</style>
