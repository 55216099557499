import { defineStore } from 'pinia';
import { apiFetch } from '../composition/useAxios';
import type { MemberStore } from './MemberStore.interface';
import { useModalStore } from './modal';
import { useStorage } from '@vueuse/core';
import router from '../router';
import { useLoadingStore } from './wait';

export interface ChangePasswordInput {
  oldPassword: string;
  newPassword: string;
}

export const useMemberStore = defineStore('member', {
  state: (): MemberStore => {
    return {
      version: '1.0.0',
      member: null,
    };
  },
  actions: {
    async getMember() {
      const loadingStore = useLoadingStore();
      const modalStore = useModalStore();
      const lang = localStorage.getItem('lang') || 'th';
      loadingStore.start('getMemberLoading');
      try {
        const { data, error } = await apiFetch('/profile ', { method: 'GET' });
        if (data?.value) {
          this.member = data.value;
        }
        if (error.value?.response?.status === 401) {
          const token = useStorage('APP_TOKEN', null);
          token.value = null;
          await router.push('/login');
          modalStore.message =
            lang === 'th'
              ? 'เซสชั่นไม่ถูกต้องหรือหมดอายุ เนื่องจากล็อคอินซ้อน'
              : 'Session expired';
          modalStore.isShow = true;
        }
      } catch (error) {
        console.error(error);
      }
      loadingStore.end('getMemberLoading');
    },
  },
});
